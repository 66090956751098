"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
exports.__esModule = true;
exports.TreeNode = void 0;
var TreeNode;
(function (TreeNode) {
    function isWildcard(treeNodeContent) {
        return treeNodeContent.path === '*';
    }
    TreeNode.isWildcard = isWildcard;
    function asContent(node) {
        return {
            path: node.path,
            data: node.data,
            children: node.children.map(function (e) { return asContent(e); })
        };
    }
    TreeNode.asContent = asContent;
    function createNode(path, parent, content) {
        return createNodeFromContent({
            path: path,
            data: content,
            children: []
        }, parent);
    }
    TreeNode.createNode = createNode;
    function isNode(node) {
        return Object.getOwnPropertyDescriptor(node, 'parent') !== undefined;
    }
    TreeNode.isNode = isNode;
    function createNodeFromContent(content, parent) {
        var _a;
        return __assign(__assign({}, content), (_a = {}, _a[Symbol.iterator] = function () {
            return this.children[Symbol.iterator]();
        }, _a.parent = parent, _a.toJSON = function () {
            return asContent(this);
        }, _a.addData = function () {
            var nodeData = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                nodeData[_i] = arguments[_i];
            }
            return appendNodeData.apply(void 0, __spreadArray([this], nodeData, false));
        }, _a.matchNode = function (expression) {
            return matchNode(this, expression.split('.'));
        }, _a));
    }
    TreeNode.createNodeFromContent = createNodeFromContent;
    function matchNode(node, expression, currentFlow) {
        if (currentFlow === void 0) { currentFlow = 0; }
        if (expression.length === 0) {
            return null;
        }
        if (currentFlow === expression.length) {
            return node;
        }
        var currentPath = expression[currentFlow];
        if (currentPath === '*') {
            return node;
        }
        for (var _i = 0, _a = node.children; _i < _a.length; _i++) {
            var child = _a[_i];
            if (child.path == currentPath) {
                var matched = matchNode(child, expression, currentFlow + 1);
                if (matched) {
                    return matched;
                }
            }
        }
        return null;
    }
    TreeNode.matchNode = matchNode;
    function createRoot() {
        return createNode("<root>");
    }
    TreeNode.createRoot = createRoot;
    function appendNodeData(treeNode) {
        var nodeData = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            nodeData[_i - 1] = arguments[_i];
        }
        nodeData.forEach(function (data) {
            var current = treeNode;
            var paths = data.expression.split('.');
            paths.forEach(function (path, idx) {
                if (path === "*") {
                    if (idx !== paths.length - 1) {
                        throw new Error("Wildcard can only be used at the end of the expression.");
                    }
                    current.data = data;
                }
                else {
                    var exists = current.children.find(function (child) { return child.path === path; });
                    if (exists) {
                        current = exists;
                    }
                    else {
                        var newNode = createNode(path, current, (idx === paths.length - 1) ? data : undefined);
                        current.children.push(newNode);
                        current = newNode;
                    }
                }
            });
        });
    }
    TreeNode.appendNodeData = appendNodeData;
    function getNodePath(treeNode) {
        if (treeNode.parent) {
            return getNodePath(treeNode.parent) + '.' + treeNode.path;
        }
        else {
            return treeNode.path;
        }
    }
    function solveTreeNode(treeNode, parent) {
        if (!isNode(treeNode)) {
            treeNode = createNodeFromContent(treeNode, parent);
        }
        treeNode.children = treeNode.children.filter(function (child) {
            if (isWildcard(child)) {
                treeNode.data = child.data;
                return false;
            }
            return true;
        }).map(function (child) { return solveTreeNode(child, treeNode); });
        if (treeNode.path && !treeNode.data) {
            console.warn("No data for path: " + getNodePath(treeNode));
        }
        return treeNode;
    }
    TreeNode.solveTreeNode = solveTreeNode;
    function parseMenuRoute(route) {
        var result = [];
        if (route.children) {
            route.children.forEach(function (child) {
                result.push.apply(result, parseMenuRoute(child));
            });
        }
        if (route.path == '/') {
            console.warn("Root route is not allowed for resources expression.");
            return [];
        }
        result.push({
            id: route.id,
            displayName: route.name,
            expression: route.path.slice(1).replace(/\//g, '.') + ".*",
            checked: false
        });
        return result;
    }
    TreeNode.parseMenuRoute = parseMenuRoute;
    function getAllowedMenu(treeNode) {
        var expression = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            expression[_i - 1] = arguments[_i];
        }
        var st = new Set();
        expression.forEach(function (exp) {
            var matched = treeNode.matchNode(exp);
            if (matched) {
                getAllowedExpression(matched, function (exp) {
                    st.add(exp);
                });
            }
        });
        return st;
    }
    TreeNode.getAllowedMenu = getAllowedMenu;
    function getAllowedExpression(treeNode, consumer) {
        var _a;
        if ((_a = treeNode.data) === null || _a === void 0 ? void 0 : _a.expression) {
            consumer(treeNode.data.expression);
        }
        treeNode.children.forEach(function (child) {
            getAllowedExpression(child, consumer);
        });
    }
    function getCheckedNodes(treeNode) {
        var _a;
        var result = [];
        if ((_a = treeNode.data) === null || _a === void 0 ? void 0 : _a.checked) {
            result.push(treeNode);
        }
        treeNode.children.forEach(function (child) {
            result.push.apply(result, getCheckedNodes(child));
        });
        return result;
    }
    TreeNode.getCheckedNodes = getCheckedNodes;
    function getCheckedParent(treeNode) {
        var _a;
        var result = [];
        if ((_a = treeNode.data) === null || _a === void 0 ? void 0 : _a.checked) {
            return [treeNode];
        }
        treeNode.children.forEach(function (child) {
            return result.push.apply(result, getCheckedParent(child));
        });
        return result;
    }
    TreeNode.getCheckedParent = getCheckedParent;
})(TreeNode = exports.TreeNode || (exports.TreeNode = {}));
