// 全局配置
let baseUrl = '', fileBaseUrl = '', port = 9003, filePort = 9001;
console.log(process.env.CMLINT_ENV, document.location.protocol, document.location.hostname)
let protocol = document.location.protocol, hostname = document.location.hostname
var localUrl = 'http://192.168.56.112:';
// var localUrl = 'http://192.168.10.57:';
var testUrl = 'http://mytemp2020.tpddns.cn:';
// var testUrl = 'http://192.168.56.130:';
// var showUrl = 'http://121.40.153.189:';
var showUrl = 'https://robot.genius-pros.com/api';
var jkUrl = 'http://47.118.48.144:';
//console.log('process.env.CMLINT_ENV', process.env.CMLINT_ENV)
// baseUrl = protocol + '//' + (hostname == 'localhost' ? '192.168.56.112' : hostname) + ':' + port

// baseUrl = process.env.CMLINT_ENV === 'production' ? 'api' : 'api2';
baseUrl = "api"
// baseUrl = 'http://192.168.56.130:9100';
//fileBaseUrl = protocol=='https:'?'wss'
// baseUrl = protocol + '//' + '192.168.10.57:9999';

// if(process.env.CMLINT_ENV === 'development'){
//   baseUrl = localUrl + port;
//   fileBaseUrl = localUrl + filePort;
// }else if(process.env.CMLINT_ENV === 'test'){
//   console.log(document.location.hostname+':'+port)
//   baseUrl = 'http://'+document.location.hostname+':'+port
//   fileBaseUrl = testUrl + filePort;
// }else if(process.env.CMLINT_ENV === 'production'){
//   baseUrl = showUrl;
//   fileBaseUrl = showUrl + filePort;
// }else if(process.env.CMLINT_ENV === 'jk'){
//   baseUrl = jkUrl + port;
//   fileBaseUrl = jkUrl + filePort;
// }else{
//   baseUrl = localUrl + port;
//   fileBaseUrl = localUrl + filePort;
// }
export {
  baseUrl,
  fileBaseUrl
}