import { createAction } from 'redux-actions'
import { message } from 'antd'
import { Cookie } from '@/utils/storage.js'
import { oauth_token, oauth_getUserGrantAccess, oauth_getUserInfo, user_login_token } from '@/api/login.js';

// 登录 获取token信息
const createSaveUser = createAction('SAVE_USER')
export const loginUser = (loginInfo) => async dispatch => {
  return new Promise((resolve, reject) => {
    // 发起异步请求  获取信息
    var url = (new URLSearchParams(window.location.search)).get('url');
    var params = new URL(decodeURIComponent(url));
    if (params.search) {
      params.search += '&url=' + url;
    } else {
      params.search = '?url=' + url;
    }
    user_login_token({ code: loginInfo, redirectUri: params.toString() }).then(res => {
      var url = new URL(window.location.href);
      url.searchParams.delete('url');
      url.searchParams.delete('code');
      console.log(url, '!!!')
      window.history.pushState({ url: url.toString() }, '', '/');
      //   window.history.push('/monitorCenter')
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}
// 获取用户信息
const createGetUser = createAction('GET_USER')
export const getUser = (token) => async dispatch => {
  return new Promise((resolve, reject) => {

    // 发起异步请求  获取用户信息
    oauth_getUserInfo().then(res => {
      window.localStorage.setItem('user', JSON.stringify(res));
      dispatch(createSaveUser(res))
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

//清除用户信息
export const clearUser = createAction('CLEAR_USER')


// routes
export const setRoutes = createAction('SET_ROUTES')
export const clearRoutes = createAction('CLEAR_ROUTES')