import React, { Component } from 'react'

export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, errorInfo) {
        // 处理错误，例如发送错误报告
        console.error(error, errorInfo);
        this.setState({ hasError: true });
    }

    render() {
        if (this.state.hasError) {
            // 显示备用UI
            return <h1>Something went wrong.</h1>;
        }
        return this.props.children;
    }
}

