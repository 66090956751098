import axios from './server.js';

// 用户列表
function user_list(organizationId, form) {
    return axios({
        method: 'get',
        url: '/manage/users?organizationId=' + organizationId,
        params: form
    })
}
// 新增用户
function user_add(form) {
    return axios({
        method: 'put',
        url: '/manage/users',
        data: form
    })
}
// 获得单个用户详情
function user_info(userId) {
    return axios({
        method: 'get',
        url: '/manage/users/' + userId,
    })
}
// 修改用户
function user_update(userId, form) {
    return axios({
        method: 'post',
        url: '/manage/users/' + userId,
        data: form
    })
}
// 删除用户
function user_delete(userId) {
    return axios({
        method: 'delete',
        url: '/manage/users/' + userId,
    })
}
// 批量删除用户
function user_batch_delete(userId) {
    return axios({
        method: 'delete',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        url: '/manage/users/' + userId + '/batch',
    })
}
// 查询用户所用的角色
function user_info_role(userId) {
    return axios({
        method: 'get',
        url: '/manage/users/' + userId + '/role',
    })
}
// 封禁用户角色
function user_ban_change(userId, roleId, ban) {
    return axios({
        method: 'patch',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        url: '/manage/users/' + userId + '/role/' + roleId + '?ban=' + ban,
    })
}
// 删除用户角色
function user_role_delete(userId, roleId) {
    return axios({
        method: 'delete',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        url: '/manage/users/' + userId + '/role/' + roleId,
    })
}
// 重置密码
function user_resetPwd(loginId) {
    return axios({
        method: 'patch',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        url: '/manage/users/' + loginId + '/fpwd',
    })
}
// 修改用户密码
function user_setPwd(params) {
    return axios({
        method: 'patch',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        url: '/manage/users/pwd',
        params: params
    })
}
// 启停用用户
function user_status(userId) {
    return axios({
        method: 'patch',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        url: '/manage/users/' + userId,
    })
}
//查询个人动作权限
function user_action(userId) {
    return axios({
        method: 'get',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        url: `/manage/users/${userId}/auth/action`,
    })
}
//查询数据权限
function user_data(userId) {
    return axios({
        method: 'get',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        url: `/manage/users/${userId}/auth/data`,
    })
}
export { user_action, user_data, user_list, user_add, user_info, user_update, user_delete, user_batch_delete, user_info_role, user_ban_change, user_role_delete, user_setPwd, user_resetPwd, user_status }