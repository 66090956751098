import asyncComponent from './asyncComponent';

const _import_views = file => asyncComponent(() => import(`@/pages/${file}`))

const asyncRouterMapList = [
  {
    path: "/",
    id: 99999,
    exact: true,
    login: true,
    layout: true,
    hidden: true,
    name: '首页',
    role: ['/home'],
    component: _import_views('home/index')
  },
  // {
  //   path: "/monitorCenter",
  //   id: 0,
  //   login: true,
  //   layout: true,
  //   name: '监控中心',
  //   icon: 'icon-zhuangtai',
  //   role: ['/monitorCenter'],
  //   component: _import_views('monitorCenter/index')
  // },
  // {
  //   path: "/controlCenter",
  //   id: 1,
  //   name: "控制中心",
  //   login: true,
  //   layout: true,
  //   icon: 'icon-kongzhi',
  //   level: 1,
  //   role: ['/controlCenter'],
  //   component: _import_views('controlCenter/index'),
  // },
  { path: "/organization", id: 2, name: "组织管理", component: _import_views('organization/index'), layout: true, level: 1, icon: 'icon-zuzhiqunzu', role: ["/organization"] },
  {
    path: "/sceneManage",
    layout: true,
    icon: 'icon-changjingguanli',
    name: "场景监控",
    id: 3,
    component: _import_views('communityManage/sceneManage/index'),
    level: 1,
    btnAuth: [
      { path: "/sceneManage/btn_add", name: "新建场景（按钮）", id: 3000001 },
      {
        path: "/sceneManage/tab_detail", name: "场景详情（按钮）", id: 301,
        tabs: [
          { path: "/sceneManage/tab_detail/detail_tab_homePage", name: "场景主页", id: 30001 },
          { path: "/sceneManage/tab_detail/detail_tab_robotManage", name: "机器人管理", id: 30002 },
          { path: "/sceneManage/tab_detail/detail_tab_orderManage", name: "订单管理", id: 30003 },
          { path: "/sceneManage/tab_detail/detail_tab_iotManage", name: "IOT设备监控", id: 30004 },]
      },
      {
        path: "/sceneManage/tab_config", name: "场景配置（按钮）", id: 302,
        tabs: [{ path: "/sceneManage/tab_config/config_tab_basicInfo", name: "场景基础信息", id: 30205 },
        { path: "/sceneManage/tab_config/config_tab_iotManage", name: "运营配置", id: 30206 },
        { path: "/sceneManage/tab_config/config_tab_dispatchFile", name: "调度文件", id: 30207 },
        { path: "/sceneManage/tab_config/config_tab_pointMange", name: "点编辑管理", id: 30211 },
        { path: "/sceneManage/tab_config/config_tab_floorInfo", name: "楼户信息", id: 30208 },
        // { path: "/sceneManage/tab_config/config_tab_dispatchScheme", name: "调度方案配置", id: 30209 },
        { path: "/sceneManage/tab_config/config_tab_robotService", name: "服务机器人配置", id: 30210 },]
      }
    ],
    tabs: [
    ],
  },
  // { path: "/sceneConfig", layout: true, name: "场景配置", id: 4, component: _import_views('communityManage/sceneConfig/index'), level: 1, role: ["/communityManage/sceneConfig"], hidden: true },
  // { path: "/sceneAdd", layout: true, name: "场景新增", id: 5, component: _import_views('communityManage/sceneAdd/index'), level: 1, role: ["/communityManage/sceneAdd"], hidden: true },
  // { path: "/sceneUpdate", layout: true, name: "场景详情", id: 6, component: _import_views('communityManage/sceneUpdate/index'), level: 1, role: ["/communityManage/sceneUpdate"], hidden: true },
  // {
  //   path: "/sceneDetail", layout: true, name: "场景详情", id: 6, component: _import_views('communityManage/sceneDetail/index'), level: 1, role: ["/communityManage/sceneDetail"], hidden: true,
  // },
  {
    path: "/robot",
    id: 9,
    login: true,
    layout: true,
    name: '机器人中心',
    icon: 'icon-jiqiren2',
    level: 1,
    role: ['/robot'],
    children: [
      {
        path: "/robot/robotManage",
        name: "机器人管理",
        id: 901,
        isShowIcon: true,
        level: 2,
        component: _import_views('robot/robotManage/index'),
      },
      {
        path: "/robot/robotLedger",
        name: "机器人台账",
        id: 902,
        isShowIcon: true,
        level: 2,
        component: _import_views('robot/robotLedger/index'),
      }
    ]
  },
  {
    path: "/orderCenter",
    name: "订单中心",
    id: 10,
    login: true,
    layout: true,
    icon: 'icon-icon-dingdan',
    role: ["/orderCenter"],
    level: 1,
    component: _import_views('orderCenter/orderManage/index')
  },
  {
    path: "/IOT",
    name: "IOT管理",
    id: 11,
    login: true,
    layout: true,
    icon: 'icon-IOTguanli',
    role: ["/IOT"],
    level: 1,
    children: [
      {
        path: "/IOT/elevator",
        name: "电梯管理",
        id: 1101,
        isShowIcon: true,
        level: 2,
        component: _import_views('IOT/elevator/index'),
      },
      // {
      //   path: "/IOT/elevation",
      //   name: "高阶电梯管理",
      //   id: 1102,
      //   isShowIcon: true,
      //   level: 2,
      //   component: _import_views('IOT/elevation/index'),
      // },
      {
        path: "/IOT/entrance",
        name: "门禁管理",
        id: 1102,
        isShowIcon: true,
        level: 2,
        component: _import_views('IOT/entrance/index'),
      },
    ]
  },
  {
    path: "/advancedSet",
    name: "高级设置",
    id: 12,
    login: true,
    layout: true,
    icon: 'icon-gaojishezhi',
    level: 1,
    role: ['/advancedSet'],
    component: _import_views('advancedSet/index'),
    tabs: [{ path: "/advancedSet/manage_tab_resourcesSet", name: "资源设置", id: 1201 },
    { path: "/advancedSet/manage_tab_robotSet", name: "机器人设置", id: 1202 },
    { path: "/advancedSet/manage_tab_appletsAuthorization", name: "服务小程序授权", id: 1203 }
    ],
  },
  // {
  //   path: "/projectVersion",
  //   name: "产品版本",
  //   id: 13,
  //   login: true,
  //   layout: true,
  //   level: 1,
  //   role: ['/projectVersion'],
  //   component: _import_views('projectVersion/index'),
  //   hidden: true
  // },
  {
    path: "/log",
    name: "日志管理",
    id: 15,
    login: true,
    layout: true,
    icon: 'icon-rizhiguanli',
    role: ["/log"],
    level: 1,
    children: [
      // { path: "/log/operation", name: "运营日志", id: 1501, component: _import_views('log/operation/index'), level: 2, role: ["/log/operation"] },
      // { path: "/log/manage", name: "管理日志", id: 1502, component: _import_views('log/manage/index'), level: 2, role: ["/log/manage"] },
      { path: "/log/abnormal", name: "异常日志", id: 1503, component: _import_views('log/abnormal/index.jsx'), level: 2, role: ["/log/abnormal"] }
    ]
  },
  {
    path: "/system",
    name: "权限管理",
    id: 14,
    login: true,
    layout: true,
    icon: 'icon-quanxian',
    role: ["/system"],
    level: 1,
    children: [
      // { path: "/system/organization", name: "组织管理", component: _import_views('system/organization/index'), level: 2, role: ["/system/organization"] },
      { path: "/system/user", name: "账户管理", id: 1401, component: _import_views('system/user/index'), level: 2, role: ["/system/user"] },
      { path: "/system/role", name: "角色管理", id: 1402, component: _import_views('system/role/index'), level: 2, role: ["/system/role"] },
      // { path: "/system/menu", name: "菜单管理", component: _import_views('system/menu/index'), level: 2, role: ["/system/menu"] }
    ]
  },
  {
    path: '/error',
    login: true,
    layout: true,
    name: 'ErrorPage',
    redirect: '/error/404',
    hidden: true,
    children: [
      { path: '/error/404', component: _import_views('error/notFound'), name: '404' },
      { path: '/error/401', component: _import_views('error/notAuth'), name: '401' }
    ]
  },
];
export const constantRouterMap = [
  // {
  //     path: "/",
  //     exact: true,
  //     login: true,
  //     layout: true,
  //     hidden: true,
  //     name: '首页',
  //     role: ['/'],
  //     component: _import_views('monitorCenter/index')
  // },
  // {
  //     path: "/home",
  //     exact: true,
  //     login: true,
  //     layout: true,
  //     hidden: true,
  //     name: '首页',
  //     role: ['/'],
  //     component: _import_views('home/index')
  // },
  // {
  //     path: "/login",
  //     name: "登录",
  //     login: false,
  //     hidden: true,
  //     component: _import_views('login/index'),
  // },
];

// setChildrenRoles的作用，给每个孩子都加上父级的role
const setChildrenRoles = routes => routes.map(route => {
  let role = route.role
  if (!role) return route
  let fn = (children, role) => {
    return children.map(child => {
      child.role = Array.from(new Set([...(child.role || []), ...role]))
      if (child.children) {
        child.children = fn(child.children, child.role)
      }
      return child
    })
  }
  if (route.children) {
    route.children = fn(route.children, role)
  }
  return route
})
export const asyncRouterMap = asyncRouterMapList
export const routerList = JSON.parse(JSON.stringify(setChildrenRoles(JSON.parse(JSON.stringify(asyncRouterMapList)))))
export const allRoutes = constantRouterMap.concat(asyncRouterMap)
export const routes = constantRouterMap