import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import 'nprogress/nprogress.css' // Progress 进度条样式
import { bindActionCreators } from 'redux'
import Layout from '@/pages/layout'
import { allRoutes, asyncRouterMap } from './config.js'
import { whiteList } from '@/whiteList.js'
import { filterRoutes } from '@/utils/filterRoutes.js'
import actions from '@/actions/index.js'
import { Cookie } from '@/utils/storage.js'
import eventBus from '@/utils/eventBus.js'
import { TreeNode } from '@/utils/resourceTree.js'
import store from '@/store/action'
import ErrorBoundary from '@/components/errorBoundary';
import { message } from 'antd'
let auth = (value) => {
    return {
        type: 'AUTH',
        value
    }
}
// 获取当前route
const getRoute = (routes, pathname) => {
    let fn = routes => routes.map(route => {
        if (route.redirect) return route
        if (route.path === pathname && !route.redirect) return route
        if (route.children) return fn(route.children).find(v => v)
        return false
    })
    return fn(routes).find(route => route)
}
/*404判断*/
const isExistPath = (routes, pathname) => routes.some(route => {
    if (route.path === pathname) return true
    if (route.children) return isExistPath(route.children, pathname)
    return false
})

/*401判断*/
const isAuth = (role, user) => {
    var roles = window.localStorage.getItem('auth') ? JSON.parse(window.localStorage.getItem('auth')) : null;
    if (roles && (roles.indexOf('*') === 0 || roles.indexOf('*') > 0)) return true
    if (!user) return false
    let status = false;
    for (let l of roles) {
        if (role[0] === l) {
            status = true
            break;
        }
    }
    return status
}

// 每个路由的权限判断
const hasPermission = (roles, route) => {
    if (route.role && roles) {
        let count = 0;
        route.role.map(item => {
            for (let l of roles) {
                if (item === l) {
                    count++;
                    break;
                }
            }
        })
        if (count === route.role.length) {
            return true
        } else {
            return false
        }
    } else {
        return true
    }
}
// 通过权限过滤路由
const filterAsyncRouter = (routes, roles) => {
    let accessedRouters = routes.filter(route => {
        if (hasPermission(roles, route)) {
            if (route.children && route.children.length) {
                route.children = filterAsyncRouter(route.children, roles)
            }
            return true
        }
        return false
    })
    return accessedRouters
}

/*是否需要重定向*/
const isRedirectPath = (routes, pathname) => routes.find(route => route.path === pathname && route.redirect && route.redirect !== route.path)

// 路由渲染
const RouteComponent = route => <Route key={route.path} exact={route.exact || false} path={route.path} component={route.component} />
const RouteComponent2 = route => route.map(route =>
    <Route key={route.path} exact={route.exact || false} path={route.path} component={route.component} />
)
// 路由表渲染
const renderRouteComponent = routes => routes.map((route, index) => {
    return route.children ? (route.children.map(route2 => route2.children && route2.children.length ? RouteComponent2(route2.children.concat([route2])) : RouteComponent(route2))) : RouteComponent(route);
})
// 带有layout的路由
const ComponentByLayout = ({ history }) => (
    <Layout history={history}>
        <ErrorBoundary>
            <Switch>
                {renderRouteComponent(allRoutes.filter(route => route.layout))}
            </Switch>
        </ErrorBoundary>
    </Layout>
)

class MainComponents extends React.Component {
    state = {
        isOk: false,
        routeList: []
    }
    componentWillMount() {
        // 登录验证
        let { loginUser, getUser, delTabView } = this.props;
        var paramsString = window.location.search;
        var searchParams = new URLSearchParams(paramsString);
        if (searchParams.get("code")) {
            loginUser(searchParams.get("code")).then(res => {
                getUser().then(res => {
                    this.setRoutesByRole(res.menuResourceTemplate)
                }).catch(err => {
                    this.setState({ isOk: true })
                    this.setRoutesByRole([])
                    if(err.status == 500){
                        message.error('获取个人信息失败，请刷新页面重试')
                    }
                })
            }).catch(err => {

            })
        } else {
            getUser().then(res => {
                this.setRoutesByRole(res.menuResourceTemplate)
            }).catch(err => {
                this.setState({ isOk: true })
                if (err.status == 403) {
                    let { setRoutes } = this.props
                    setRoutes(allRoutes[0])
                }else if(err.status == 500){
                    this.setRoutesByRole([])
                    message.error('获取个人信息失败，请刷新页面重试')
                }
            })
        }
        this.dataInit(this.props)
    }
    componentWillReceiveProps(nextProps) {
        this.dataInit(nextProps)
    }
    componentDidUpdate() {

    }
    //数据初始化
    dataInit(props) {
        let { addTabView, addBreadCrumbs, setOpenKeys } = props
        let pathname = props.location.pathname
        let router = filterRoutes(pathname)
        //添加tab
        addTabView({ pathname })
        //面包屑
        addBreadCrumbs(router)
        //slidebar展开的节点
        setOpenKeys(router.filter(route => route.children).map(route => route.path))
        // 权限

    }
    //获取用户信息
    async getUserInfo(cb) {
        try {
            await this.props.getUser(Cookie.get('Auth_Token'))
            cb && cb()
        } catch (e) {
            // message.error(e)
        }
    }

    // 设置路由
    setRoutesByRole(roles) {
        let { routes, setRoutes } = this.props
        let accessedRouters = [];
        this.asyncRouterMapEdit(asyncRouterMap, roles)
        accessedRouters = asyncRouterMap;
        this.asyncRouterMapFilter(accessedRouters)
        // console.log(accessedRouters);
        setRoutes(accessedRouters)
        this.setState({ isOk: true })
    }
    asyncRouterMapFilter(accessedRouters) {
        accessedRouters.map(item => {
            if (item.btnAuth || item.tabs) {
                delete item.children
            }
            if (item.children) {
                this.asyncRouterMapFilter(item.children)
            }
        })
    }
    asyncRouterMapEdit(rouresList, menuList) {
        if (menuList.includes('menu.*') || menuList.includes('*')) {
            store.dispatch(auth(menuList));
            let { routeList } = this.state
            rouresList.map(item => {
                item.isShow = true
                routeList.push(item.path)
                if (item.children) {
                    this.asyncRouterMapEdit(item.children, menuList)
                }
            })
            this.setState({ routeList: routeList })
            this.allRouterMapEdit(rouresList, rouresList)
        } else {
            // menuList.forEach((v, index) => {
            //     if (v.includes("menu.sceneManage")) {
            //         menuList = Array.from(new Set([...menuList, "menu.sceneConfig.*", "menu.sceneUpdate.*", "menu.sceneDetail"]))
            //     }
            // })
            // console.log(asyncRouterMap)
            let menuRootList = asyncRouterMap.filter(item => { return item.path != '/' })
            this.allBth(menuRootList);
            let rootString = { path: '*', name: '*', children: menuRootList }
            let listRoot = TreeNode.createRoot()
            listRoot.addData(...TreeNode.parseMenuRoute(rootString))
            // console.log(listRoot);
            for (let _i in listRoot.children) {
                listRoot.children[_i].path == 'error' && (listRoot.children.splice(_i, 1))
                !listRoot.children[_i].path.length && (listRoot.children.splice(_i, 1))
            }
            // let Objlist = ['menu.IOT.elevator.*', 'menu.IOT.agreement.elevatorAgreement.*', 'menu.system.user.*']
            let list = [], menuResourceList = [], menu = [], arr = []
            menuList.map(item => {
                item = item.replace(/^menu\./, '')
                menuResourceList.push(item)
            })
            // console.log(menuResourceList);
            store.dispatch(auth(menuResourceList));
            menuResourceList = Array.from(TreeNode.getAllowedMenu(listRoot, ...menuResourceList))
            for (let l of menuResourceList) {
                l = '/' + l.slice(0, l.length - 2).split('.').join('/')
                list.push(l)
            }
            list.map(v => {
                this.allMenuString(list, v)
            })
            list = Array.from(new Set(list)).filter(item => { return item.indexOf('btn_') === -1 && item.indexOf('tab_') === -1 }).concat(whiteList)
            // list = list.filter(item => { return item.indexOf('btn_') === -1 && item.indexOf('tab_') === -1 })
            // list = list.concat(whiteList)
            // console.log(list);
            // store.dispatch(auth(list));
            this.allRouterMapEdit(rouresList, list)
            this.setState({ routeList: list })
        }
    }
    allRouterMapEdit(rouresList, list) {
        rouresList.map(item => {
            list.map(v => {
                if (item.path == v) {
                    item.isShow = true
                }
            })
            if (item.children) {
                this.allRouterMapEdit(item.children, list)
            }
        })
    }
    allMenuString(list, str) {
        let p = str.slice(0, str.lastIndexOf('/'))
        if (p.length > 0) {
            list.push(p)
            this.allMenuString(list, p)
        }
    }
    allBth(list) {
        list.map(item => {
            if (item.children?.length) {
                if (item.btnAuth) { item.children = [...item.btnAuth, ...item.children] }
                if (item.tabs) { item.children = [...item.tabs, ...item.children] }
            } else {
                if (item.btnAuth || item.tabs) {
                    item.children = []
                    if (item.btnAuth) { item.children = [...item.btnAuth, ...item.children] }
                    if (item.tabs) { item.children = [...item.tabs, ...item.children] }
                }
            }
            // if (item.btnAuth) { item.children = [...item.btnAuth, ...item.children] }
            if (item.children) {
                this.allBth(item.children)
            }
        })
    }
    // 401 
    render() {
        let { location: { pathname }, user } = this.props
        eventBus.$emit('axiosInterceptorsFun', this.props)
        // 404
        if (!isExistPath(allRoutes, pathname)) return <Redirect to='/error/404' />

        //当前路径路由信息
        let currRoute = getRoute(allRoutes, pathname)
        // 401
        if (this.state.routeList.indexOf('/error/401') > -1) {
            let currRoutelist = this.state.routeList.filter((item) => { return item == currRoute.path })
            if (!currRoutelist.length) {
                return <Redirect to='/error/401' />
            }
        }
        // 重定向子路径
        let route = isRedirectPath(allRoutes, pathname)
        if (route) return <Redirect to={route.redirect} />
        // 网页title
        document.title = currRoute.name
        return (

            <Switch>
                {renderRouteComponent(allRoutes.filter(route => !route.layout))}
                {this.state.isOk &&
                    <Route path="/" component={ComponentByLayout} />
                }
            </Switch>

        )
    }
}
export default connect(
    state => ({
        user: state.user,
        routes: state.routes,
    }),
    dispatch => bindActionCreators(actions, dispatch)
)(MainComponents)
