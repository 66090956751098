import React from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { Layout, Menu, message, notification, Popover, Modal, Form, Button, Input } from 'antd';
import { ArrowRightOutlined, MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { oauth_logout } from '@/api/login.js';
import { Cookie } from '@/utils/storage.js'
import { user_setPwd } from '@/api/user.js';
import { notice_getInTimeNotice } from '@/api/common.js';
import { GMTToStr } from '@/utils/time.js';
import connect from '@/utils/connect.js'
import BreadCrumbs from './breadCrumbs/index.js'
import TabViews from './tabViews/index.js'
import eventBus from '@/utils/eventBus';

import '../../assets/iconfont/iconfont.css';
import './index.scss';
import logoImg from '../../assets/image/logo2.jpg'

// 页面关闭前，清除定时器（测试发现切换菜单时也会触发）
window.onbeforeunload = function (event) {
  // Layout.clearTimerFunction();
};
const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};
const tailLayout = {
  wrapperCol: { offset: 0, span: 24 },
};

const menuItems = [];
const createSubMenuComponents = (route, pathname, history) => {
  const children = route.children.filter(route => !route.hidden && route.isShow);
  menuItems.push({
    label: route.name,
    key: route.path,
    icon: (<i className={['iconfont route-icon-level1 action', route.icon].join(' ')}></i>),
    children: children.map(item => {
      return !item.children || !item.children.length ?
        {
          label: (<Link to={item.path} onClick={e => {
            history.replace(item.path)
            window.sessionStorage.setItem('path', item.path)
            e.preventDefault()
          }}><span className={['', route.level === 2 ? 'level2-padding' : 'level3-padding'].join(' ')}>{item.name}</span></Link>),
          key: item.path,
        }
        : createSubMenuComponents(item, pathname, history)
    }),
  })
};

@withRouter
@connect
class SideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogFormVisible: false,
      form: {},
      loginFlag: false,
      flag: false,
      messageCount: 0,
      noticeTimer: null,
      collapsed: window.localStorage.getItem('collapsed') == 'true' ? true : false,
      theme: 'light' // 两种主题风格
    };
    SideBar.clearTimerFunction = this.clearTimerFunction.bind(this);
    const { children, state, history } = props;
    const { location: { pathname } } = props.history
    // 获取菜单items
    // eslint-disable-next-line no-const-assign
    if (!menuItems.length) {
      props.state.routes.filter(route => !route.hidden && route.isShow).map(route => {
        if (!route.children || !route.children.length) {
          menuItems.push({
            label: (<Link to={route.path} onClick={e => {
              history.replace(route.path)
              window.sessionStorage.setItem('path', route.path)
              e.preventDefault()
            }}>
              <span>{route.name}</span>
            </Link>),
            key: route.path,
            icon: (<i className={['iconfont route-icon-level1 action', route.icon].join(' ')}></i>)
          })
          return
        }
        return createSubMenuComponents(route, pathname, history)
      })
    }
  }
  componentDidMount () {
    console.log('执行吗')
    setTimeout(() => {
      var node = document.getElementsByClassName('ant-menu-submenu-placement-rightTop');
      if (node.length) {
        node[0].classList.add('ant-menu-submenu-hidden');
      }
    }, 2000)

    /* 消息提醒开始 */
    // var noticeDuration = 2000, that = this;
    // this.setState({
    //     noticeTimer: setInterval(function () {
    //         var originArr = [];
    //         if (document.getElementsByClassName('ant-notification').length) {
    //             var ele = document.getElementsByClassName('ant-notification-notice');
    //             for (let l of ele) {
    //                 originArr.push(l.getElementsByClassName('notification')[0].getAttribute('data-uuid'));
    //             }
    //         }
    //         notice_getInTimeNotice().then(res => {
    //             var arr = [];
    //             res.data = res.data ? res.data : [];
    //             res.data.map(item => {
    //                 var status = true;
    //                 for (let l of originArr) {
    //                     if (item.noticeUuid == l) {
    //                         status = false;
    //                     }
    //                 }
    //                 if (status) {
    //                     item.updateTime = GMTToStr(item.updateTime).split(' ')[1];
    //                     handle(item, that)
    //                 }
    //             })
    //         }).catch(err => {
    //             if (err.code === 401) {
    //                 that.clearTimerFunction();
    //             }
    //         })
    //     }, noticeDuration)

    // })
    // function handle(noticeObj, that) {
    //     var count = 1;
    //     // 是否添加滚动条判断
    //     if (document.getElementsByClassName('ant-notification').length) {
    //         count = document.getElementsByClassName('ant-notification-notice').length + 1;
    //         if ((window.innerHeight - 55) - (133 + 16) * count < 133) {
    //             document.getElementsByClassName('ant-notification')[0].classList.add('addClass');
    //         } else {
    //             if (document.getElementsByClassName('ant-notification')[0]) {
    //                 document.getElementsByClassName('ant-notification')[0].classList.remove('addClass');
    //             }
    //         }
    //     }
    //     // 消息展示
    //     var message = (
    //         <div className="notification" data-uuid={noticeObj.noticeUuid}>
    //             <span className="module">{noticeObj.noticeModel * 1 === 0 ? '订单' : (noticeObj.noticeModel * 1 === 1 ? '异常' : (noticeObj.noticeModel * 1 === 3 ? 'IOT设备' : ''))}</span>
    //             <span className="title" style={{ margin: '0 100px 0 30px' }}>{noticeObj.title}</span>
    //             <span className="time font-size-12">{noticeObj.updateTime}</span>
    //         </div>
    //     );
    //     var description = (
    //         <div>
    //             <div>{noticeObj.content}</div>
    //             <div style={{ display: noticeObj.noticeType === 0 ? 'block' : 'none' }} className="position-right cursor-pointer font-size-16" onClick={showTip.bind(that, noticeObj, that)}><ArrowRightOutlined /></div>
    //         </div>
    //     );
    //     // noticeType  0 提醒 1 通知
    //     var duration = noticeObj.noticeType === 0 ? 60 : (noticeObj.noticeType === 1 ? 15 : '');
    //     notification.open({
    //         message: message,
    //         description: description,
    //         duration: duration,
    //         placement: 'bottomRight',
    //         onClick: () => {
    //             console.log('Notification Clicked!');
    //         },
    //     });
    //     function showTip(item, that) {
    //         // 出现模块 0 订单 1 异常 3 iot设备
    //         if (item.noticeModel * 1 === 0) {
    //             // if(item.title === '异常订单'){
    //             //     that.props.history.push({ pathname: '/orderCenter/orderManage' });
    //             // }else{
    //             that.props.history.push({ pathname: '/orderCenter/orderManage', query: { platformOrderId: item.sourceId } });
    //             // }
    //         } else if (item.noticeModel * 1 === 1) {
    //             if (item.sourceId && item.communityId) {
    //                 that.props.history.push({ pathname: '/controlCenter', query: { robotId: item.sourceId, communityId: item.communityId } });
    //             }
    //         } else if (item.noticeModel * 1 === 3) {
    //             that.props.history.push({ pathname: '/IOT/elevator', query: { elevatorId: item.sourceId } });
    //         }
    //     }
    // }
    /* 消息提醒结束 */
  }
  componentWillReceiveProps () {
  }
  componentWillUnmount () {
    this.clearTimerFunction();
  }
  clearTimerFunction () {
    clearInterval(this.state.noticeTimer);
    if (this.state.noticeTimer !== null) {
      this.setState({
        noticeTimer: null
      })
    }
    // 解决在组件销毁后设置state，防止出现内存泄漏的情况，原因是在组件挂载（mounted）之后进行了异步操作，异步请求或者设置了定时器等
    this.setState = (state, callback) => {
      return;
    };
  }
  // 消息提醒
  infoClick () {

  }
  // 登出
  loginOut () {
    this.clearTimerFunction();
    oauth_logout().then(res => {
      var obj = { currentIndex: null, communityId: null, isMove: false }, obj2 = { robotId: null, communityId: null };
      window.sessionStorage.setItem('stayCommunityObj', JSON.stringify(obj));
      window.sessionStorage.setItem('stayRobotObj', JSON.stringify(obj2));
      // Cookie.remove('ycpwd');
      window.localStorage.removeItem('user')
      window.sessionStorage.setItem('sceneEditId', '');
      sessionStorage.removeItem('communityIsShow')
      this.props.clearUser();
      this.props.getUser();
      this.props.clearTabView();
      this.props.history.push('/')
    })
  }
  changePassword () {
    var obj = {
      userName: JSON.parse(window.localStorage.getItem('user')).username,
      oriPassword: null,
      password1: null,
      password2: null,
    };
    this.setState({
      dialogFormVisible: true,
      form: obj
    }, function () {
      var that = this;
      setTimeout(function () {
        that.refs.dialogForm.setFieldsValue({
          oriPassword: null,
          password1: null,
          password2: null,
        })
      }, 50)
    });
  }
  inputChange (e, type) {
    if (type === 1) {
      this.setState({ form: Object.assign({}, this.state.form, { oriPassword: e.target.value }) })
    } else if (type === 2) {
      this.setState({ form: Object.assign({}, this.state.form, { password1: e.target.value }) })
    } else if (type === 3) {
      this.setState({ form: Object.assign({}, this.state.form, { password2: e.target.value }) })
    }
  }
  submit () {
    if (this.state.form.password1 === this.state.form.password2) {
      var reg = /(?!^[0-9]*$)(?!^[a-zA-Z]*$)^([a-zA-Z0-9]{2,})$/;
      if (!reg.test(this.state.form.password1) || this.state.form.password1.length < 6) {
        message.warning('新密码为不少于6位字母数字组合，请修改！');
        return
      }
      user_setPwd({ oldPwd: this.state.form.oriPassword, newPwd: this.state.form.password1 }).then(res => {
        message.success('操作成功！');
        this.setState({ dialogFormVisible: false }, function () {
          this.loginOut();
        });
      }).catch(err => {

      })
    } else {
      message.warning('新密码两次输入不一致，请重新输入！');
    }
  }
  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
    window.localStorage.setItem('collapsed', !this.state.collapsed);
    eventBus.$emit('toggle');
  };
  render () {
    const { children } = this.props;
    let { openKeys, breadCrumbs } = this.props.state;
    const rootSubmenuKeys = ['/monitorCenter', '/controlCenter', '/robot', '/orderCenter', '/IOT', '/system', '/abnormalManage'];
    const onOpenChange = keys => {
      const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
      if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.props.setOpenKeys(keys);
      } else {
        this.props.setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
      }
    };

    const content = (
      <div className='popover-user'>
        <div className="user-wrap">
          <div className="user-item" onClick={this.changePassword.bind(this)}>更改密码</div>
        </div>
      </div>
    );
    const user = JSON.parse(window.localStorage.getItem('user'));
    function goHome () {
      if (window.location.href.split('#')[1] !== '/') {
        this.props.history.push('/');
      }
    }

    if (this.state.loginFlag) {
      return <Redirect to="/login" />
    }
    return (
      <Layout>
        <Header>
          {/* <Header className="header" style={{ minWidth: '1200px' }}> */}
          <div className="system-name cursor-pointer" onClick={goHome.bind(this)}><img style={{ width: '25px', height: '25px', marginRight: '10px', position: 'relative', top: '-2px' }} src={logoImg} />运营管理系统</div>
          <div className="login-out iconfont icon-logout float-right cursor-pointer" onClick={this.loginOut.bind(this)}></div>
          <div className="line-decollate float-right"></div>
          <div className='system-info float-right font-size-14'>
            <div>{user ? user.username : ''}</div>
          </div>
          <div className="line-decollate float-right"></div>
          <Popover placement="bottomRight" content={content} trigger="click">
            <div className="iconfont icon-shezhi font-size-18 float-right cursor-pointer"></div>
          </Popover>
          <div className="line-decollate float-right"></div>
          <div className={['info-wrap iconfont icon-lingdang font-size-18 float-right cursor-pointer', this.state.messageCount ? 'info-wrap-active' : ''].join(' ')} onClick={this.infoClick.bind(this)}>
            <div>{this.state.messageCount ? this.state.messageCount : ''}</div>
          </div>
          <div className="clearfix"></div>
        </Header>
        <Layout>
          {/* <Layout style={{ minWidth: this.props.history.location.pathname == '/sceneDetail' ? '1530px' : '1200px' }}> */}
          <Sider width={240} className="site-layout-background" trigger={null} collapsible collapsed={this.state.collapsed}>
            <Menu
              theme={this.state.theme}
              mode="inline"
              style={{ height: 'calc(100% - 40px)', borderRight: 0 }}
              onOpenChange={onOpenChange}
              openKeys={openKeys}
              selectedKeys={[breadCrumbs[breadCrumbs.length - 1].path]}
              items={menuItems}
            >
            </Menu>
            <div className="collapsible-name">
              <span className="cursor-pointer trigger" onClick={this.toggle}>{this.state.collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}</span>
            </div>
          </Sider>
          <Content
            className="site-layout-background"
            style={{
              backgroundColor: '#ffffff'
            }}
          >
            {/* <div style={{ padding: '0 15px', marginTop: '10px', marginBottom: '20px' }}>
              <TabViews className="tabViews" />
              <div className="clearfix"></div>
            </div> */}
            <div style={{ padding: '0 15px 24px', height: 'calc(100% - 32px)', boxSizing: 'border-box', marginTop: '20px' }}>
              {children}
            </div>
          </Content>
        </Layout>
        <Modal title="更改密码" width={450} open={this.state.dialogFormVisible} footer={null} maskClosable={false} onCancel={(e => { this.setState({ dialogFormVisible: false }) })}>
          <Form {...layout} name="basic" ref='dialogForm' initialValues={{ remember: true }} onFinish={this.submit.bind(this)}>
            <Form.Item label="用户名" name="userName">
              <span>{this.state.form.userName}</span>
            </Form.Item>
            <Form.Item label="原密码" name="oriPassword" rules={[{ required: true, message: '请输入原密码!' }]}>
              <Input.Password value={this.state.form.oriPassword} onChange={(e) => this.inputChange(e, 1)} />
            </Form.Item>
            <Form.Item label="新密码" name="password1" rules={[{ required: true, message: '请输入新密码!' }]}>
              <Input.Password value={this.state.form.password1} onChange={(e) => this.inputChange(e, 2)} />
            </Form.Item>
            <Form.Item label="重复新密码" name="password2" rules={[{ required: true, message: '请重新输入新密码!' }]}>
              <Input.Password value={this.state.form.password2} onChange={(e) => this.inputChange(e, 3)} />
            </Form.Item>
            <Form.Item {...tailLayout} className="button-form">
              <Button type="primary" htmlType="submit" >
                {this.state.dialogStatus === 'create' ? '确认添加' : '确认修改'}
              </Button>
            </Form.Item>
          </Form>
        </Modal>

      </Layout>
    )
  }
}
export default SideBar;
