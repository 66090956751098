import { allRoutes } from '@/router/config.js'
import store from '@/store/action'
/*
    当前路径的路由信息
 */

export const filterRoutes = pathname => {
    let pathSnippets = pathname.split('/').filter(path => path)
    let paths = pathSnippets.map((path, index) => `/${pathSnippets.slice(0, index + 1).join('/')}`)
    let filter = (arr, index) => {
        if (index < paths.length) {
            let p = paths[index]
            index++
            let route = arr.find(route => route.path === p)
            return route ? [route].concat(route.children ? filter(route.children, index) : []) : []
        }
        return []
    }
    return [allRoutes.find(route => route.path === '/')].concat(filter(allRoutes, 0))
}

/*
 判断对象是否相等
 */
export const diff_obj = (obj1, obj2) => {
    let o1 = obj1 instanceof Object
    let o2 = obj2 instanceof Object
    if (!o1 || !o2) {/*  判断不是对象  */
        return obj1 === obj2
    }

    if (Object.keys(obj1).length !== Object.keys(obj2).length) return false

    for (let attr in obj1) {
        let t1 = obj1[attr] instanceof Object
        let t2 = obj2[attr] instanceof Object
        if (t1 && t2) {
            return diff_obj(obj1[attr], obj2[attr])
        } else if (obj1[attr] !== obj2[attr]) {
            return false
        }
    }
    return true
}

/**
 * 过滤tabs权限
 * @param {string} string - 需要过滤的tabs权限字符串，以逗号分隔，例如 'advancedSet,manage_' 'advancedSet,advancedSet.manage_tab_appletsAuthorization,applets_'
 * @param {array} list - 字符串数组或name匹配数组，用于过滤结果
 * @returns {array} - 过滤后的结果数组
 */

export const filterTabs = (string, list) => {
    const allAuth = store.getState()
    const arr = string.split(',').map((item, index, array) => {
        // 对除最后一项以外的每一项添加 '.*' 后缀
        return index !== array.length - 1 ? item + '.*' : item;
    });
    // 检查用户是否拥有该权限
    const hasRequestedPermissions = arrHasSameValue(allAuth, arr);
    // 如果用户没有拥有该权限且不是全局管理员，则根据最后一项进行过滤
    let resultArr = [];
    if (!hasRequestedPermissions.length && !allAuth.includes("menu.*")) {
        // 从所有权限中筛选出包含最后一项的权限
        resultArr = allAuth.filter(item => item.includes(arr[arr.length - 1]));
        // 对过滤后的权限进行处理，去除结尾的 '.*' 并只保留最后一部分
        resultArr = resultArr.map(item => item.split('_').pop().replace('.*', ''));
    } else {
        // 如果用户拥有该权限或者是全局管理员，则返回全部权限
        resultArr = ['*'];
    }
    // 根据过滤后的权限数组，从传入的列表中过滤出匹配的项
    if (resultArr.length && resultArr[0] !== '*') {
        resultArr = arrHasSameValue(list, resultArr);
    } else if (resultArr[0] === '*') {
        resultArr = list;
    } else {
        resultArr = [];
    }
    return resultArr;
}

export const arrHasSameValue = (sourceArray, targetArray) => {
    return sourceArray.filter(item => item?.key ? targetArray.includes(item.name) : targetArray.includes(item));
}





