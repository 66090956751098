import axios from './server.js';
import qs from 'qs';

// 获取token
function oauth_token (form) {
  return axios({
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    method: 'post',
    url: '/oauth/oauth2/token',
    data: qs.stringify(form)
  })
}
// 登出
function oauth_logout () {
  return axios({
    method: 'get',
    url: '/manage/users/logout',
  })
}
// 获取角色权限的id
function oauth_getUserGrantAccess (form) {
  return axios({
    method: 'post',
    url: '/oauth/oauth2/getUserGrantAccess',
    data: form
  })
}
// 获取当前用户信息
function oauth_getUserInfo () {
  return axios({
    method: 'get',
    url: '/manage/users/current',
  })
}
// 新的登录开始
// 查询client等信息
function oauth_client () {
  return axios({
    method: 'get',
    url: '/manage/oauth',
  })
}
// 获取token
function user_login_token (params) {
  return axios({
    method: 'get',
    url: '/manage/users/login',
    params: params
  })
}
export { oauth_token, oauth_logout, oauth_getUserGrantAccess, oauth_getUserInfo, oauth_client, user_login_token }