function GMTToStr(time) {
  if (!time) {
    return null;
  }
  var date = new Date(time)
  var Str = date.getFullYear() + '-' +
    ((date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)) + '-' +
    (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ' +
    (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':' +
    (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':' +
    (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
  return Str;
}
function GMTToStrmm(time) {
  if (!time) {
    return null;
  }
  var date = new Date(time)
  var Str = date.getFullYear() + '-' +
    ((date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)) + '-' +
    (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ' +
    (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':' +
    (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())
  return Str;
}
function parse_time(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  let date;
  if (typeof time == 'object') {
    date = time;
  } else {
    if (('' + time).length === 10) time = parseInt(time) * 1000;
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key];
    if (key === 'a') return ['一', '二', '三', '四', '五', '六', '日'][value - 1];
    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }
    return value || 0;
  });
  return time_str;
}
// 时间转换
/*eslint no-extend-native: ["error", { "exceptions": ["Date"] }]*/
Date.prototype.Format = function (fmt) {
  var o = {
    "M+": this.getMonth() + 1, // 月份
    "d+": this.getDate(), // 日
    "h+": this.getHours(), // 小时
    "m+": this.getMinutes(), // 分
    "s+": this.getSeconds(), // 秒
    "q+": Math.floor((this.getMonth() + 3) / 3), // 季度
    "S": this.getMilliseconds() // 毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + ""));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
}

// 将秒转换为 天-时-分-秒
function secondToDate(msd) {
  var time = (msd === null || msd === '') ? null : msd * 1;
  if (time !== null && time !== "") {
    if (time > 60 && time < 60 * 60) {
      time = parseInt(time / 60.0) + "分钟" + parseInt((parseFloat(time / 60.0) -
        parseInt(time / 60.0)) * 60) + "秒";
    }
    else if (time >= 60 * 60 && time < 60 * 60 * 24) {
      time = parseInt(time / 3600.0) + "小时" + parseInt((parseFloat(time / 3600.0) -
        parseInt(time / 3600.0)) * 60) + "分钟" +
        parseInt((parseFloat((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60) -
          parseInt((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60)) * 60) + "秒";
    } else if (time >= 60 * 60 * 24) {
      time = parseInt(time / 3600.0 / 24) + "天" + parseInt((parseFloat(time / 3600.0 / 24) -
        parseInt(time / 3600.0 / 24)) * 24) + "小时" + parseInt((parseFloat(time / 3600.0) -
          parseInt(time / 3600.0)) * 60) + "分钟" +
        parseInt((parseFloat((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60) -
          parseInt((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60)) * 60) + "秒";
    }
    else {
      time = parseInt(time) + "秒";
    }
  }
  return time;
}

// 秒转换为天小时分钟秒
function formattingTime(count) {
  if (!count) {
    return count;
  } else {
    count = count * 1;
  }
  var time = '';
  var second = count % 60;
  var minute = parseInt(parseInt(count) / 60) % 60;
  time = second + "秒";
  if (minute >= 1) {
    time = minute + "分" + second + "秒";
  }
  var hour = parseInt(parseInt(count / 60) / 60) % 24;
  if (hour >= 1) {
    time = hour + "小时" + minute + "分" + second + "秒";
  }
  var day = parseInt(parseInt(parseInt(count / 60) / 60) / 24);
  if (day >= 1) {
    time = day + "天" + hour + "小时" + minute + "分" + second + "秒";
  }
  return time;
}

// 秒转换为小时分钟秒
function timeToHMS(count) {
  if (!count) {
    return [0, 0, 0];
  } else {
    count = count * 1;
  }
  var time = [];
  var second = count % 60;
  var minute = parseInt(parseInt(count) / 60) % 60;
  var hour = parseInt(parseInt(count / 60) / 60) % 24;
  time = [hour, minute, second]
  return time;
}

// 获取服务器当前时间
function getServerDate() {
  var xhr = null;
  if (window.XMLHttpRequest) {
    xhr = new window.XMLHttpRequest();
  } else { // ie
    //   xhr = new ActiveObject("Microsoft")
  }

  xhr.open("GET", "/", false)//false不可变
  xhr.send(null);
  var date = xhr.getResponseHeader("Date");
  return new Date(date);
}
// 时间查
function timeDifference(start, end) {
  // 格式为 02:02
  if ((end.split(":")[0] * 1 - start.split(":")[0] * 1) * 60 + (end.split(":")[1] * 1 - start.split(":")[1] * 1) < 59) {
    return false;
  } else {
    return true;
  }
}


//获取某个日子的日期
function getDay(day) {
  var today = new Date();
  var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
  today.setTime(targetday_milliseconds); //注意，这行是关键代码
  var tYear = today.getFullYear();
  var tMonth = today.getMonth();
  var tDate = today.getDate();
  tMonth = doHandleMonth(tMonth + 1);
  tDate = doHandleMonth(tDate);
  return tYear + "-" + tMonth + "-" + tDate;
}

//获取当前月份
function doHandleMonth(month) {
  var m = month;
  if (month.toString().length == 1) {
    m = "0" + month;
  }
  return m;
}

// 根据已知时间推算前一天时间
export { GMTToStr, parse_time, secondToDate, formattingTime, timeToHMS, getServerDate, timeDifference, getDay, GMTToStrmm }
