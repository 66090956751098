import { createStore, compose, applyMiddleware } from 'redux'
import DevTools from '@/utils/devtools.js'
import reducer from '@/reducers/index.js'
import thunk from 'redux-thunk'
const fn = store => next => action => {
    next(action)
}
const configureStore = preloadedState => createStore(
    reducer,
    preloadedState,
    compose(
        applyMiddleware(thunk, fn),
        DevTools.instrument()
    )
)
export default configureStore 
