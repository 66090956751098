import axios from './server.js';
// 小区信息
function getCommunityList () {
  return axios({
    method: 'get',
    url: '/remoteCenter/exceptionLog/getCommunityNames',
  })
}
// 机器人信息
function getRobotList () {
  return axios({
    method: 'get',
    url: '/remoteCenter/exceptionLog/getRobotIds',
  })
}
// 获取消息
function notice_getInTimeNotice () {
  return axios({
    method: 'get',
    url: '/manage/notice/getInTimeNotice',
  })
}
// 获取当前时间
function server_currentTime () {
  return axios({
    method: 'get',
    url: '/manage/common/server/time',
  })
}
// 查询所有场景类型
function community_Type () {
  return axios({
    method: 'get',
    url: '/manage/communities-type/-',
  })
}
// 获取机器人列表
function robot_list_simple (form) {
  return axios({
    method: 'get',
    url: '/manage/iot/robots/list/simple',
    params: form
  })
}
////////////////////////////////////////////////////////
export { getCommunityList, getRobotList, notice_getInTimeNotice, server_currentTime, community_Type, robot_list_simple }
