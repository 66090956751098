import React from 'react'
import { Link } from 'react-router-dom'
import { Breadcrumb } from 'antd'
import './index.scss'
import connect from '@/utils/connect.js'
// import creatHistory from 'history/createHashHistory'  //返回上一页这段代码
// const history = creatHistory();//返回上一页这段代码
const Item = Breadcrumb.Item

@connect
class BreadCrumbs extends React.Component {
  goBackPage () {
    // history.goBack();
    window.history.back(-1);
  }
  render () {
    let { breadCrumbs } = this.props.state
    return (
      <div id="breadCrumbs">
        <span className="iconfont icon-weizhi float-left"></span>
        <Breadcrumb style={{ float: 'left' }} className={this.props.className}>
          {breadCrumbs.map((route, index) => {
            return breadCrumbs.length > 1 && index === 0 ? <Breadcrumb.Item key={route.path}></Breadcrumb.Item> :
              (<Breadcrumb.Item key={route.path}>{route.name}</Breadcrumb.Item>)
          })}
        </Breadcrumb>
        {/* <span className="margin-left-30 font-size-12 color-999 cursor-pointer" onClick={this.goBackPage.bind(this)}>返回上一页</span> */}
        <div className="clearfix"></div>
      </div>
    )
  }
}
export default BreadCrumbs