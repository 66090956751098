import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Router from './router';
import App from './App';
import * as serviceWorker from './serviceWorker';
import configureStore from '@/store/index.js'
import { Provider } from 'react-redux';
import '@/actions/index.js'
import "react-app-polyfill/ie9.js";
import "react-app-polyfill/stable.js";
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import 'moment/locale/zh-cn';
import { ConfigProvider } from 'antd';


const store = configureStore()
ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={zh_CN}>
      <Router />
    </ConfigProvider>
  </Provider>
  , document.getElementById('root'));

serviceWorker.unregister();
