import axios, { AxiosInstance, AxiosInterceptorManager, AxiosPromise, AxiosRequestConfig, AxiosResponse, HeadersDefaults } from 'axios'

export interface AxiosRequestConfigEx<D = any> extends AxiosRequestConfig<D> {
  ignoreError?: boolean
}

export interface AxiosResponseEx<D = any> extends AxiosResponse<D> {
  config: AxiosRequestConfigEx<D>
}

export interface AxiosDefaultsEx<D = any> extends Omit<AxiosRequestConfigEx<D>, 'headers'> {
  headers: HeadersDefaults
}

export interface AxiosInstanceEx extends AxiosInstance {
  withConfig(config: AxiosRequestConfigEx): AxiosInstanceEx

  defaults: AxiosDefaultsEx

  _axios: AxiosInstance

  interceptors: {
    request: AxiosInterceptorManager<AxiosRequestConfigEx>
    response: AxiosInterceptorManager<AxiosResponseEx>
  }

  (config: AxiosRequestConfigEx): AxiosPromise

  (url: string, config?: AxiosRequestConfigEx): AxiosPromise

  getUri(config?: AxiosRequestConfigEx): string

  request<T = any, R = AxiosResponseEx<T>, D = any>(config: AxiosRequestConfigEx<D>): Promise<R>

  get<T = any, R = AxiosResponseEx<T>, D = any>(url: string, config?: AxiosRequestConfigEx<D>): Promise<R>

  delete<T = any, R = AxiosResponseEx<T>, D = any>(url: string, config?: AxiosRequestConfigEx<D>): Promise<R>

  head<T = any, R = AxiosResponseEx<T>, D = any>(url: string, config?: AxiosRequestConfigEx<D>): Promise<R>

  options<T = any, R = AxiosResponseEx<T>, D = any>(url: string, config?: AxiosRequestConfigEx<D>): Promise<R>

  post<T = any, R = AxiosResponseEx<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfigEx<D>): Promise<R>

  put<T = any, R = AxiosResponseEx<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfigEx<D>): Promise<R>

  patch<T = any, R = AxiosResponseEx<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfigEx<D>): Promise<R>

  patchForm<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfigEx<D>): Promise<R>

  postForm<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfigEx<D>): Promise<R>

  putForm<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfigEx<D>): Promise<R>
}

function createInstance(axiosInstance: AxiosInstance = axios.create(), _config?: AxiosRequestConfigEx): AxiosInstanceEx {
  const baseAxios = axiosInstance

  const anonymous1 = function (config: AxiosRequestConfigEx): AxiosPromise {
    return baseAxios({ ..._config, ...config })
  }

  const anonymous2 = function (url: string, config?: AxiosRequestConfigEx): AxiosPromise {
    return baseAxios(url, { ..._config, ...config })
  }

  const axiosEx: AxiosInstanceEx = Object.assign(
    Object.assign(
      anonymous2,
      anonymous1,
    ),
    {
      defaults: baseAxios.defaults,
      delete<T = any, R = AxiosResponseEx<T>, D = any>(url: string, config?: AxiosRequestConfigEx<D>): Promise<R> {
        return baseAxios.delete(url, { ..._config, ...config })
      },
      get<T = any, R = AxiosResponseEx<T>, D = any>(url: string, config?: AxiosRequestConfigEx<D>): Promise<R> {
        return baseAxios.get(url, { ..._config, ...config })
      },
      getUri(config?: AxiosRequestConfigEx): string {
        return baseAxios.getUri({ ..._config, ...config })
      },
      head<T = any, R = AxiosResponseEx<T>, D = any>(url: string, config?: AxiosRequestConfigEx<D>): Promise<R> {
        return baseAxios.head(url, { ..._config, ...config })
      },
      options<T = any, R = AxiosResponseEx<T>, D = any>(url: string, config?: AxiosRequestConfigEx<D>): Promise<R> {
        return baseAxios.options(url, { ..._config, ...config })
      },
      patch<T = any, R = AxiosResponseEx<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfigEx<D>): Promise<R> {
        return baseAxios.patch(url, data, { ..._config, ...config })
      },
      patchForm<T = any, R = AxiosResponseEx<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfigEx<D>): Promise<R> {
        return baseAxios.patchForm(url, data, { ..._config, ...config })
      },
      post<T = any, R = AxiosResponseEx<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfigEx<D>): Promise<R> {
        return baseAxios.post(url, data, { ..._config, ...config })
      },
      postForm<T = any, R = AxiosResponseEx<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfigEx<D>): Promise<R> {
        return baseAxios.postForm(url, data, { ..._config, ...config })
      },
      put<T = any, R = AxiosResponseEx<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfigEx<D>): Promise<R> {
        return baseAxios.put(url, data, { ..._config, ...config })
      },
      putForm<T = any, R = AxiosResponseEx<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfigEx<D>): Promise<R> {
        return baseAxios.putForm(url, data, { ..._config, ...config })
      },
      request<T = any, R = AxiosResponseEx<T>, D = any>(config: AxiosRequestConfigEx<D>): Promise<R> {
        return baseAxios.request({ ..._config, ...config })
      },

      withConfig(config: AxiosRequestConfigEx): AxiosInstanceEx {
        return createInstance(axiosEx, { ..._config, ...config })
      },
      _axios: baseAxios,
      interceptors: {
        request: baseAxios.interceptors.request as AxiosInterceptorManager<AxiosRequestConfigEx>,
        response: baseAxios.interceptors.response as AxiosInterceptorManager<AxiosResponseEx>,
      },
    },
  )
  return axiosEx
}

export default Object.assign(createInstance, {
  create(config?: AxiosRequestConfigEx): AxiosInstanceEx {
    return createInstance(axios.create(), config)
  },
})
