import axios from '@/api/server';
import WebSocketClient from '@bimini/common-websocket'
import { v4 } from 'uuid'
import { baseUrl } from '@/config/envconfig'
import messageSupportInit, {CommunityMessage, ElevatorListMessage, ElevatorStatusMessage, RobotMessage_OrderVO, Point, RobotListMessage, RobotMessage, RobotPathMessage} from '@bimini/robot-message-support'
import eventBus from './eventBus';
messageSupportInit()
const host = document.location.host
const hostname = document.location.hostname
const protocol = document.location.protocol == 'http:' ? 'ws' : 'wss'
const randomUuid = v4();
localStorage.setItem('uuid', randomUuid)
// const newHost = (hostname == '192.168.10.27' || hostname == 'localhost' || hostname.indexOf('robot.genius-pros.com')) ? '192.168.56.112:9100' : host
const websocketClient = WebSocketClient(`${protocol}://${host}/${baseUrl}/manage/websocket?sessionId=${randomUuid}`)
console.log(`${protocol}://${host}/${baseUrl}/manage/websocket?sessionId=${randomUuid}`);
var num = 0
websocketClient.on("message:text", (event) => {
  //console.log("receive text:", event.data.text)
})
websocketClient.on("message:binary", (event) => {
  //console.log("receive binary:", event.data.binary)
})

websocketClient.on("message:other", (event) => {
  //console.debug("receive other", event.data)
  if (event.data.__type__ == CommunityMessage) {
    eventBus.$emit("getCommunityList", event.data.msg)
  } else if (event.data.__type__ == ElevatorListMessage) {
    eventBus.$emit("getElevatorList", event.data.msg)
  } else if (event.data.__type__ == ElevatorStatusMessage) {
    eventBus.$emit("getElevatorStatusList", event.data.msg)
  } else if (event.data.__type__ == RobotMessage_OrderVO) {
    eventBus.$emit("getOrderList", event.data.msg)
  } else if (event.data.__type__ == Point) {
    eventBus.$emit("getPointList", event.data.msg)
  } else if (event.data.__type__ == RobotListMessage) {
    //console.log(event.data);
    eventBus.$emit("getRobotList", event.data)
  } else if (event.data.__type__ == RobotMessage) {
    eventBus.$emit("getRobotMessageList", event.data.msg)
  } else if (event.data.__type__ == RobotPathMessage) {
    eventBus.$emit("getRobotPathList", event.data)
  }
})

websocketClient.on("open", event => {
  console.log('send it');
  eventBus.$emit("getReconnection", num)
  num++
})

// websocketClient.on("close", (event) => {
//   console.log('服务器已断开');
// })
// websocketClient.onMessage(CommunityMessage, (message) => {
//   console.log(message);
// })
// websocketClient.on("open", (event) => {
//   axios.withConfig({ baseURL: baseUrl }).get("/manage/view/communities/-", {
//     // axios.withConfig({ baseURL: baseUrl }).get(url, {
//     headers: {
//       "x-session-id": randomUuid
//     }
//   }).then(res => {
//     console.log("success get community", res)
//   }).catch(err => {
//     console.log("error get community", err)
//   })
//   console.log("send it")
// })


// setTimeout(() => {
//   websocketClient.start()
// }, 3000)

websocketClient.start()

// export function getConnectRes (url) {

//   if (websocketClient) {
//     console.log(url);
//     console.log(websocketClient);

//     websocketClient.on("open", (event) => {
//       // axios.withConfig({ baseURL: baseUrl }).get("/manage/view/communities/-", {
//       axios.withConfig({ baseURL: baseUrl }).get(url, {
//         headers: {
//           "x-session-id": randomUuid
//         }
//       }).then(res => {
//         console.log("success get community", res)
//       }).catch(err => {
//         console.log("error get community", err)
//       })
//       console.log("send it")
//     })

//   }

// }

export default websocketClient