import axios from 'axios';
import { message, Modal, Layout } from 'antd';
import { baseUrl } from '@/config/envconfig';
import { oauth_logout } from '@/api/login.js';
import { Cookie } from '@/utils/storage.js'
import eventBus from '@/utils/eventBus.js'
import { oauth_client } from '@/api/login.js';
import axiosEx from '@/utils/axiosEx'
axios.defaults.baseURL = baseUrl;
axios.defaults.timeout = 60000;
axios.defaults.withCredentials = true;
//// 取消重复请求
// CancelToken能为一次请求‘打标识’
// isCancel用于判断请求是不是被CancelToken取消的
const { CancelToken, isCancel } = axios

// 请求队列，缓存发出的请求
const cacheRequest = {}

/**
 * @desc 删除缓存队列中的请求
 * @param {String} reqKey 本次请求的唯一标识 url&method
 */
function removeCacheRequest(reqKey) {
  if (cacheRequest[reqKey]) {
    // 这里调用的就是上面的CancelToken生成的c函数，调用就会取消请求
    cacheRequest[reqKey]()
    delete cacheRequest[reqKey]
  }
}
////
/**
 * 如果有多个请求都是 403 就需要这个开关 来控制message的展示个数
 * 展示一个之后  关闭阀门
 */
let messageFlag = false;
/**
 * props是app.js页面传入的 this.props
 * 用于路由跳转  当403的时候 进行路由跳转
 */
let props = "";

/**
 * 当使用这个js的时候 会监听这个自定义事件
 * 改变props的值 
 */
eventBus.$on((propsObj) => {
  props = propsObj
}, 'axiosInterceptorsFun')

const goLoginFun = (value) => {
  if (messageFlag === false) {
    if (value.config.url !== "/manage/users/current") {
      message.error("登录已过期，请重新登录！");
    }
    messageFlag = true;
    setTimeout(() => {
      messageFlag = false
    }, 2000)
    // 401 登录过期，跳转登录
    // if(value.config.url === "/manage/users/current"){
    //     var paramsString = window.location.search;
    //     var searchParams = new URLSearchParams(paramsString);
    //     if (searchParams.get("code")) {
    //         props.loginUser(searchParams.get("code")).then(res => {
    //             props.getUser().then(res=>{

    //             }).catch(err=>{

    //             })
    //         }).catch(err => {
    //             goOauth();
    //         })
    //     }else{
    //         goOauth();
    //     }
    // }else{
    //     goOauth();
    // }
    goOauth();
  }
}
function goOauth() {
  let protocol = document.location.protocol, hostname = document.location.hostname;
  oauth_client().then(res => {
    var params = new URL(window.location.href);
    params.searchParams.delete('code');
    params.searchParams.delete('url');
    params.searchParams.set('url', params.toString());
    // @ts-ignore
    var href = (hostname == 'localhost') ? (protocol + '//' + hostname + ':8001/') : res.oauthUrl + '/';
    window.location.href = href + '?platform=' + 'robot' + '&redirect_uri=' + encodeURIComponent(params.toString())
      // @ts-ignore
      + '&client_id=' + res.clientId + '&response_type=' + res.responseType + '&scope=' + res.scope + '&domain=' + res.domainId;
  }).catch(err => {

  })
}
// http request 拦截器
axios.interceptors.request.use(config => {
  config.responseType = config.responseType ? config.responseType : 'json';
  const { url, method, params } = config
  // 请求地址和请求方式组成唯一标识，将这个标识作为取消函数的key，保存到请求队列中
  const reqKey = `${url}&${method}&${JSON.stringify(params)}`
  // 如果存在重复请求，删除之前的请求
  removeCacheRequest(reqKey)
  // 将请求加入请求队列
  config.cancelToken = new CancelToken(c => {
    cacheRequest[reqKey] = c
  })
  return config
}, error => {
  Promise.reject(error)
})
axios.interceptors.response.use(response => {
  // 请求成功，从队列中移除
  const { url, method, params } = response.config
  removeCacheRequest(`${url}&${method}&${JSON.stringify(params)}`)
  // @ts-ignore
  if (parseInt(response.status / 100) == 2) {
    //console.log(response.data);
    return Promise.resolve((response.headers["x-total-count"])
      ? { data: response.data, total: response.headers["x-total-count"] * 1 }
      : (response.headers["content-disposition"] ? response
        : (response.data)));
  } else {
    console.log(response.data);
    message.error(response.data.message)
    return Promise.reject(response.data);
  }
}, error => {
  // 请求失败，使用isCancel来区分是被CancelToken取消，还是常规的请求失败
  if (isCancel(error)) {
    // 通过CancelToken取消的请求不做任何处理
    return Promise.reject({
      message: '重复请求，自动拦截并取消'
    })
  }
  if (error.response.status == 401) {
    goLoginFun(error.response);
  } else if (error.response.status == 403 && error.response.data.code == 2610014) {
    accessFunction()
  } else if (error.response.status == 403) {
    if (messageFlag === false) {
      message.error('没有该权限,请联系管理员');
      messageFlag = true;
      setTimeout(() => {
        messageFlag = false
      }, 2000)
      return Promise.reject(403)
    }
  } else if (error.response.status == 413) {
    message.error('请求体过大');
  } else {
    console.log(error.response.data);
    if (error.response.data && error.response.data.error) {
      message.error(error.response.data.error);
    }
    if (error.response.data && error.response.data.message) {
      console.log(error.response.data.message);
      message.error(error.response.data.message);
    }
  }
  return Promise.reject(error.response);
})
function accessFunction() {
  if (document.getElementsByClassName('ant-modal-root').length === 0) {
    Modal.confirm({
      title: '提示',
      content: '未授权，请先登出，重新登录！',
      okText: '确认',
      cancelText: '取消',
      onOk() {
        // 登出接口
        oauth_logout().then(res => {
          var obj = { currentIndex: null, communityId: null, isMove: false }, obj2 = { robotId: null, communityId: null };
          window.sessionStorage.setItem('stayCommunityObj', JSON.stringify(obj));
          window.sessionStorage.setItem('stayRobotObj', JSON.stringify(obj2));
          // Cookie.remove('ycpwd')
          window.localStorage.removeItem('user')
          window.sessionStorage.setItem('sceneEditId', '');
          window.location.reload()
        })
      },
      onCancel() {
      },
    });
  }
}


// export default axiosEx
const http = axiosEx(axios)
window.httpRequest = http
export default axiosEx(axios)