import React from 'react'
import { Tag, Tabs } from 'antd'
import connect from '@/utils/connect.js'
import { Link, withRouter } from 'react-router-dom'
import './index.scss'
import { constantRouterMap } from '../../../router/config'
import BreadCrumbs from '../breadCrumbs/index.js'

const { TabPane } = Tabs;


@connect
@withRouter
class TabViews extends React.Component {
  onChange = activeKey => {
    this.setState({ activeKey }, function () {
      this.props.history.push({ pathname: activeKey });
    })
  };
  onEdit = (targetKey, action) => {
    this[action](targetKey);
  };
  remove = targetKey => {
    let { tabViews } = this.props.state;
    let obj, url;
    tabViews.forEach((pane, i) => {
      if (pane.path === targetKey) {
        obj = pane;
        if (window.location.hash.slice(1) == targetKey) {
          i != 0 && (url = tabViews[i - 1].path)
        }
      }
    });
    url && this.props.history.push({ pathname: url });
    this.props.delTabView(obj);
  };
  render() {
    let { state, delTabView, location } = this.props
    let pathname = location.pathname;
    const tabItems = [];
    {state.tabViews.map(pane => (
      tabItems.push({
        label: pane.name,
        key: pane.path,
        children: (<>
        <BreadCrumbs className='breadCrumbs' />
        </>)
      })
    ))}
    return (
      <div id="tabViews">
        <Tabs
          hideAdd
          type="editable-card"
          onChange={this.onChange}
          onEdit={this.onEdit}
          activeKey={pathname == state.activeKey ? state.activeKey : pathname}
          items={tabItems}
        >
        </Tabs>
      </div>
    )
  }
}

export default TabViews